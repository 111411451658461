<template>
  <lazy-cart-entwash-small-basket
    v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'"
  />
  <lazy-cart-rhudaur-sidebar
    v-else-if="appConfig.VueSettingsPreRun.CartLayout === 'Rhudaur'"
  />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
